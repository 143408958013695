export class HSServicesRequest {

    type: string
    pocType: number
    categoryId: number
    serviceId: number
    homeCollections: boolean
    parentServiceId: number
    scheduleId: number
    serviceIdList: Array<number>;
    packageId: number

    searchTerm: string

    brandId: number;
    latitude: number
    longitude: number
    pinCode: string
    profileId: string;
    pocId: number;
    service_Type: number;

    from: number = 0
    size = 100
    // brandId: Int? = Application.brand?.brandId ?? 0

    departmentIdList1: Array<number>;
    departmentIdList2: Array<number>;
    departmentIdList3: Array<number>;
    departmentIdList4: Array<number>;

    popularOnly: boolean


    /* patientProfileId: string = Application.activeProfile.id.asstring()
    parentProfileId: string = Application.activeProfile.id.asstring() */

}