import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TextTruncate from 'react-text-truncate';
import { withTranslation } from '../../../../i18n';
import servicesData from '../../../../public/static/vdc_services.json';
import Auth from '../../../auth/auth';
import HSComponent from "../../../auth/hscomponent";
import CookieUtil from '../../../auth/util/cookieUtil';
import { Config } from '../../../base/portal_env_config/config';
import CommonUtil from '../../../base/utils/commonUtil';
import Format from '../../../base/utils/format';
import { UserRating } from '../../../model/user/rating';
import CategoryComponent from '../../diagnostics/common/categories.component';
import DiagnosticService from '../../diagnostics/diagnostic.service';
import { FETCH_HEALTH_CU_CAT_LIST, FETCH_HEALTH_CU_PACK_LIST, SERVICE_CARD_DATA, SET_ALL_SERVICES } from '../../diagnostics/diagnostic.types';
import LayoutTypes from '../../layout/layout.types';
import Link from '../../layout/widgets/activelink/activelink';
import LocationDropdwnService from '../../util-components/hs-location/hs-location.service';
import connect from '../dashboard.connect';
import DashboardService from '../dashboard.service';
import { GET_FREQUENT_PURCHASES, GET_LOGIN_DATA, GET_RATING_DETAILS } from '../dashboard.types';
import './home.style.scss';
import FileUtil from '../../../base/utils/fileutil';
import CleverTapUtil from '../../../clevertap/clevertaputil';
import AnalyticsConstant from '../../../clevertap/analyticsconstants';
import StorageUtil from '../../../auth/util/storageUtil';
import dynamic from 'next/dynamic';
const FeedbackComponent = dynamic(() => import('../rating/feedback.component'));
const RatingComponent = dynamic(() => import('../rating/rating.component'));
import Faqs from "../../../components/vijaya/faqs.component";
import ToastComponent from "../../layout/widgets/toast/toast.component";
import HappyCustomerList from '../../vijaya/happycustomer.component';
import HealthcarePlatform from '../../vijaya/healthcareplatform.component';

const bannerres = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    },
};

const categoryres = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 10,
        slidesToSlide: 10,
    },
    desktop: {
        breakpoint: { max: 1199, min: 768 },
        items: 7,
        slidesToSlide: 7,
    },
    tablet: {
        breakpoint: { max: 767, min: 576 },
        items: 5,
        slidesToSlide: 5,
    },
    mobile: {
        breakpoint: { max: 575, min: 0 },
        items: 3,
        slidesToSlide: 3,
    },
};

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 3,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 767, min: 576 },
        items: 2,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 575, min: 0 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 50,
    },
};

const healthcheckupservices = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 4,
        slidesToSlide: 4,
    },
    desktop: {
        breakpoint: { max: 1199, min: 992 },
        items: 3,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 991, min: 576 },
        items: 2,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 575, min: 0 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 50,
    },
};

class HomeComponent extends HSComponent {
    props: any;
    state: any = {
        catList: [],
        fileterdServices: [],
        serviceCatMap: new Map<number, any>(),
        searchText: "",
        isMobileView: false,
        showMobileDiscountPopup: false,
        loadExtras: false
    }
    constructor(props) {
        super(props);
        // $(window).load('shown.bs.modal', function () {
        //     
        // }
        // )
        // $("#myModalBanner").on('shown.bs.modal', function () {
        //     $(window).focus();
        // })

    }

    static async getInitialProps({ store, pathname, query }) {
        let serviceMap = new Map<number, any>();
        let categories = [];
        let tempList = [];
        let data = {
            serviceCatMap: new Map<number, any>(),
            catList: [],
            fileterdServices: []
        }

        servicesData.forEach(data => {
            let category: any = { categoryId: 0, categoryName: "", isSelected: false };
            category.categoryId = data.categoryId;
            category.categoryName = data.categoryName;
            categories.push(category);
            serviceMap.set(+data.categoryId, data.subServiceList);
            data.subServiceList.forEach(item => {
                tempList.push(item);
            });
        });


        data.catList = categories;
        data.fileterdServices = tempList;
        tempList && store.dispatch({ type: SET_ALL_SERVICES, payload: tempList });
        data && store.dispatch({ type: SERVICE_CARD_DATA, payload: data });
        store.dispatch({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: true });
        await HomeComponent.serviceCalls(store.getState()).
            then(([loginData, freqPurchaseList, ratingDetails, catList, packageList
            ]) => {
                store.dispatch({ type: GET_LOGIN_DATA, payload: loginData });
                store.dispatch({ type: GET_FREQUENT_PURCHASES, payload: freqPurchaseList });
                store.dispatch({ type: GET_RATING_DETAILS, payload: ratingDetails });
                store.dispatch({ type: FETCH_HEALTH_CU_CAT_LIST, payload: catList });
                store.dispatch({ type: FETCH_HEALTH_CU_PACK_LIST, payload: packageList });
                store.dispatch({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: false });

            }).catch((err) => {
                store.dispatch({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: false });
                console.log(err);
            });
        // return {categories:categories,serviceMap:serviceMap};
    }

    isMobileDashboard() {
        let hideNavigation = false;
        if ((typeof window != "undefined") && window?.screen?.width <= 575) {
            hideNavigation = true;
        }
        return hideNavigation;
    }

    static async serviceCalls(modProps) {
        let isLoggedIn = modProps?.auth?.isLoggedIn;
        let location = LocationDropdwnService.getSerLocation();
        return await Promise.all([
            new Promise((resolve, reject) => {
                if (isLoggedIn) {
                    DashboardService.getDataWhenLoggedIn({
                        "brandId": Config?.portal?.appId,
                        "latitude": location?.locationCoordinates?.lat,
                        "longitude": location?.locationCoordinates?.lon,
                        "pinCode": location?.pinCode || Config?.portal?.defaultLoc?.pinCode,
                        "pocId": 0,
                        "profileId": modProps?.auth?.userDetails?.profileId || 0,
                        "service_Type": 0
                    }).then(res => {
                        resolve(res.data)
                    }).catch((err) => {
                        ToastComponent.warning('Connection issue !! Try Again Later')
                        console.log(err)
                        resolve([])
                    })
                } else {
                    DashboardService.getDataWhenLoggedOut({
                        "brandId": Config?.portal?.appId,
                        "latitude": location?.locationCoordinates?.lat,
                        "longitude": location?.locationCoordinates?.lon,
                        "pinCode": location?.pinCode || Config?.portal?.defaultLoc?.pinCode,
                        "pocId": 0,
                        "profileId": modProps?.auth?.userDetails?.profileId || 0,
                        "service_Type": 0
                    }).then(res => {
                        resolve(res.data)
                    }).catch((err) => {
                        ToastComponent.warning('Connection issue !! Try Again Later')
                        console.log(err)
                        resolve([])
                    })
                }
            }),
            new Promise((resolve, reject) => {
                DashboardService.getFrequentPurchases({
                    "brandId": Config?.portal?.appId,
                    "latitude": location?.locationCoordinates?.lat,
                    "longitude": location?.locationCoordinates?.lon,
                    "pinCode": location?.pinCode || Config?.portal?.defaultLoc?.pinCode,
                    "pocId": 0,
                    "profileId": modProps?.auth?.userDetails?.profileId || 0,
                    "service_Type": 0
                }).then(res => {
                    resolve(res.data)
                }).catch((err) => {
                    ToastComponent.warning('Connection issue !! Try Again Later')
                    console.log(err)
                    resolve([])
                })
            }),
            new Promise((resolve, reject) => {
                DashboardService.getRatingDetails(
                    {
                        profileId: modProps?.auth?.userDetails?.profileId
                    }).then(res => {
                        resolve(res.data)
                    }).catch((error) => {
                        console.log(error);
                        resolve({});
                    })
            }),
            new Promise((resolve, reject) => {
                DiagnosticService.getDiagnosticWalkinTestCategories({
                    brandId: Config?.portal?.appId,
                    homeCollections: false,
                    pinCode: LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode,
                    categoryId: +1002
                }).then(res => {
                    resolve(res.data);
                }).catch((err) => {
                    ToastComponent.warning('Connection issue !! Try Again Later')
                    console.log(err)
                    resolve([])
                })
            }),
            new Promise((resolve, reject) => {
                DiagnosticService.getDiagnosticWalkinTestList({
                    brandId: Config?.portal?.appId,
                    pocId: 0,
                    categoryId: 4,
                    departmentIdList2: [],
                    departmentIdList3: [],
                    departmentIdList4: [],
                    from: 0,
                    size: 100,
                    homeCollections: false,
                    popularOnly: false,
                    pinCode: LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode,
                    type: 0,
                    parentProfileId: modProps.auth?.userDetails?.profileId || 0,
                    patientProfileId: modProps.auth?.userDetails?.profileId || 0,
                }).then(res => {
                    resolve(res.data)
                }).catch((err) => {
                    console.log(err)
                    resolve([])
                })
            })
        ])
    }

    componentDidMount() {
        this.setState({ loadExtras: true });
        let popUpStatus = CookieUtil.getCookie('_hideRatingPopUp');
        console.log("FeedbackCheck: ", popUpStatus + ">>>: ", this.props?.auth?.userDetails?.profileId + ">>>>: ", Config.portal?.dashboardConfig?.showFeeback);

        if (Config.portal?.dashboardConfig?.showFeeback && this.props?.auth?.userDetails?.profileId && this.props?.auth?.userDetails?.profileId > 0
            && !popUpStatus) {
            this.getRatingDetails();
        }

        this.props.setCoupon({
            name: '',
            applied: false
        });

        this.setState({ baseCategoryItem: this.props?.diagnostic?.healthCat });

        this.setState({ isMobileView: this.isMobileDashboard() });


        let serviceMap = new Map<number, any>();
        console.log('homeservicesData', servicesData);
        servicesData.forEach(data => {
            let category: any = { categoryId: 0, categoryName: "", isSelected: false };
            category.categoryId = data.categoryId;
            category.categoryName = data.categoryName;

            serviceMap.set(+data.categoryId, data.subServiceList);

        });
        let { catList, fileterdServices } = this.props?.diagnostic?.diagServiceData;
        this.setState({ catList, serviceCatMap: serviceMap, fileterdServices })

        // Show followup test notification
        this.showUpcomingTests();
        $('#myModalBanner').modal('show');

        let mobileDiscountPopupData = StorageUtil.getUnencryptedLocalStorageData('MobileDiscountPopup');
        if (mobileDiscountPopupData) {
            mobileDiscountPopupData = JSON.parse(mobileDiscountPopupData);
        }
        if (!mobileDiscountPopupData || !(mobileDiscountPopupData && mobileDiscountPopupData.timestamp &&
            +mobileDiscountPopupData.timestamp > (+new Date().getTime() - (60 * 60 * 1000)))) {
            this.setState({ showMobileDiscountPopup: true }, () => { $('#mobileDiscountModal').modal('show'); });
            StorageUtil.setUnencryptedLocalStorage('MobileDiscountPopup', { "timestamp": new Date().getTime() });
        }

        CleverTapUtil.customEventTrigger({}, AnalyticsConstant.DASHBOARD);
    }

    showUpcomingTests() {
        let popUpStatus = CookieUtil.getCookie('_upcomingTestsPopUp');
        if (!popUpStatus && this.props?.auth?.userDetails?.profileId && this.props?.auth?.userDetails?.profileId > 0) {
            DiagnosticService.getUpcomingTests({ profileId: this.props?.auth?.userDetails?.profileId })
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.length > 0) {
                        let testList = response.data.data;
                        let message = '';
                        let serviceIds = [];
                        testList.forEach(item => {
                            if (message.length > 0) {
                                message += ', ';
                            }
                            message += item.serviceName;
                            serviceIds.push(item.serviceId);
                        })
                        message = testList.length > 1 ? ('It’s 3 months since your last ' + message + ' tests. You are due for the next check-up. Book an appointment now.') : ('It’s 3 months since your last ' + message + '  test. You are due for the next check-up. Book an appointment now.');
                        this.setState({ showFollowupPopup: true, followupTestMessage: message, followupServiceIds: serviceIds });
                        $('#followupTestModal').modal('show');
                        CookieUtil.setCookie('_upcomingTestsPopUp', true, (new Date().getTime() + Config?.portal?.featureConfig?.popUpDislayInterval));
                    }
                }).catch(err => {
                    console.error(err);
                });

        }
    }

    onFollowupBook() {
        DiagnosticService.getDiagnosticWalkinTestList({
            brandId: Config?.portal?.appId,
            serviceIdList: this.state.followupServiceIds,
            from: 0,
            size: 100,
            homeCollections: false,
            popularOnly: false,
            pinCode: LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode,
            type: 0,
            parentProfileId: 0,
            patientProfileId: 0,
        }).then(res => {
            if (res.data && res.data.length > 0) {
                let selectedServiceListMap = this.props.diagnostic?.selectedServiceList || new Map<number, any>();
                if (!selectedServiceListMap?.has) {
                    selectedServiceListMap = new Map<number, any>();
                }
                res.data.forEach(item => {
                    selectedServiceListMap.set(+item?.serviceId, item);
                })
                this.props?.setSelectedServiceList(false, selectedServiceListMap);
                ToastComponent.success('Tests added to cart.');
            }
        }).catch((err) => {
            console.log(err)
        })


    }

    async getRatingDetails() {
        // await DashboardService.getRatingDetails(
        //     {
        //         profileId: this.props?.auth?.userDetails?.profileId
        //         // profileId: 118135
        //     }).then(res => {
        //         this.props.setRatingDetail(res.data);
        //         // CookieUtil.setCookie('_hideRatingPopUp', true, (new Date().getTime() + Config?.portal?.featureConfig?.popUpDislayInterval));
        //     }).catch((error) => {
        //         console.log(error);
        //         this.props.setRatingDetail({})
        //     })
        if (this.props?.dashboard?.ratingDetail && this.props?.dashboard?.ratingDetail?.userRating === UserRating.INITIAL &&
            this.props?.dashboard?.ratingDetail?.orderId != null && this.props?.dashboard?.ratingDetail?.orderId != "") {
            CookieUtil.setCookie('_hideRatingPopUp', true, (new Date().getTime() + Config?.portal?.featureConfig?.popUpDislayInterval));
            if (Config?.portal?.featureConfig?.popUpMode)
                $('#feedBackModal').modal('show');
            else
                $('#ratingModal').modal('show');
        }
    }

    async getDetails() {
        // this.props.setLayoutLoading(true);
        // await Promise.all([
        //     new Promise((resolve, reject) => {
        //         DiagnosticService.getDiagnosticWalkinTestCategories({
        //             brandId: Config?.portal?.appId,
        //             homeCollections: false,
        //             pinCode: LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode,
        //             categoryId: +1002
        //         }).then(res => {
        //             resolve(res.data);
        //         }).catch((err) => {
        //             this.props.setLayoutLoading(false);
        //             ToastComponent.warning('Connection issue !! Try Again Later')
        //             console.log(err)
        //             resolve([])
        //         })
        //     }),
        //     new Promise((resolve, reject) => {
        //         DiagnosticService.getDiagnosticWalkinTestList({
        //             brandId: Config?.portal?.appId,
        //             pocId: 0,
        //             categoryId: 4,
        //             departmentIdList2: [],
        //             departmentIdList3: [],
        //             departmentIdList4: [],
        //             from: 0,
        //             size: 100,
        //             homeCollections: false,
        //             popularOnly: false,
        //             pinCode: LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode,
        //             type: 0,
        //             parentProfileId: this.props?.auth?.userDetails?.profileId || 0,
        //             patientProfileId: this.props?.auth?.userDetails?.profileId || 0,
        //         }).then(res => {
        //             resolve(res.data)
        //         }).catch((err) => {
        //             console.log(err)
        //             this.props.setLayoutLoading(false);
        //             resolve([])
        //         })
        //     })
        // ]).then(([catList, packageList]) => {

        //     this.props.setHealthCatList(catList);
        //     this.props.setHealthPackList(packageList);
        //     this.setState({ baseCategoryItem: this.props.diagnostic.healthCat });
        //     this.props.setLayoutLoading(false);
        // })
    }

    getFilteredSubServices() {
        let serMap = this.state.serviceCatMap;
        let subServiceList = [];
        this.state?.catList.forEach(item => {
            let tempList = [];
            if (item?.isSelected) {
                tempList = serMap.get(item?.categoryId);
                tempList.forEach(temp => {
                    subServiceList.push(temp);
                });
            }
        });
        if (subServiceList && subServiceList.length === 0) {
            subServiceList = this.props?.diagnostic?.allServices;
        }

        console.log("Filter: ", subServiceList);
        this.setState({ fileterdServices: subServiceList });
    }

    manageDeepLinkOnClick(item: any) {
        console.log("manageDeepLinkOnClick: ", item);

        if (item?.couponCode && item?.couponCode?.length > 0) {
            this.props?.setCoupon({
                name: item.couponCode.toUpperCase(),
                applied: false
            })
        } else {
            this.props?.setCoupon({
                name: '',
                applied: false
            })
        }
        if (item.slugLink && item.slugLink != "") {
            if (item.slugLink.trim().startsWith("http")) {
                if (window) {
                    window.open(item.slugLink.trim(), '_blank');
                }
            } else {
                let address = LocationDropdwnService.getSerLocation();
                let cityName = address?.title != undefined ? address?.title : address?.cityName
                Auth.navigateTo("/" + item.slugLink, undefined, cityName);
            }
        } else if (item.deepLink && item.deepLink?.length > 0) {
            Auth.navigateTo("/" + item.deepLink, undefined, item?.header);
        }
    }

    goToDetail(item, isPackage: boolean = false) {
        let address = LocationDropdwnService.getSerLocation();
        let cityName = address?.cityName ? address?.cityName : 'hyderabad';
        if (isPackage) {
            return `/health-checkup/${CommonUtil.convertToSlug(cityName)}/${item?.slugName}`;
        } else {
            return `/test/${CommonUtil.convertToSlug(cityName)}/${item?.slugName}`;
        }

    }

    addToCart(item) {
        // let address = LocationDropdwnService.getSerLocation();
        // let cityName = address?.title != undefined ? address?.title : address?.cityName

        // if (isNavigateToDetail && item?.displayDescription) {
        //     Auth.navigateTo(`/health-checkup/${CommonUtil.convertToSlug(cityName)}/${item?.slugName}`);
        // } else {
        let selectedServiceListMap = this.props?.diagnostic?.selectedServiceList || new Map<number, any>();
        if (!selectedServiceListMap?.has) {
            selectedServiceListMap = new Map<number, any>();
        }

        if (selectedServiceListMap.has(+item.serviceId)) {
            selectedServiceListMap?.delete(+item.serviceId);
        } else {
            selectedServiceListMap.set(+item.serviceId, item);
        }
        this.props.setSelectedServiceList(false, selectedServiceListMap);
        // }
    }

    searchOptions = {
        "placeholder": "Nearest Centre",
        "inputType": "any",
        "searchType": false,
        "selectedDisplayVar": "title",
        "searchviewcolumnList": [
            {
                "variable": "title",
                "filters": "text"
            },
            {
                "variable": "pinCode",
                "filters": "text"
            }
        ]
    }

    setPriceDetails(item) {
        let grossPrice = 0;
        let netPrice = 0;
        let discountPrice = 0;
        let userPrivType = this.props?.auth?.userDetails?.privilegeType?.[0] || 0;
        if (Config?.portal?.featureConfig?.enablePrivilegePriceOnCards
            && userPrivType && userPrivType > 0) {
            let specialPrices = item?.walkinOrderPriceDetails?.specialPriceList || [];
            if (specialPrices && specialPrices?.length > 0) {
                let priceItem = specialPrices.find(priceItem => +priceItem?.type === +userPrivType);
                grossPrice = priceItem?.grossPrice;
                netPrice = priceItem?.netPrice;
                discountPrice = priceItem?.discountPrice
            }
            else {
                grossPrice = item?.grossPrice;
                netPrice = item?.netPrice;
                discountPrice = item?.discountPrice
            }
        } else {
            grossPrice = item?.grossPrice;
            netPrice = item?.netPrice;
            discountPrice = item?.discountPrice
        }
        return (<React.Fragment>
            {(grossPrice > netPrice) &&
                <React.Fragment>
                    <div className='d-inline-block'>
                        <p className='txt-danger'>{Format.currencyFormat(grossPrice, this.state?.currency, this.state?.locale)}</p>
                        <span className="discount-info">{`${CommonUtil.getDiscountPercentage(item.grossPrice, item.netPrice)}`}%&nbsp;<small>OFF</small></span>
                    </div>
                    <h5 className="d-inline-block align-top">{Format.currencyFormat(netPrice, this.state?.currency, this.state?.locale)}</h5>
                </React.Fragment>
            }
            {(grossPrice === netPrice) &&
                <h5 className="d-inline-block">{Format.currencyFormat(netPrice, this.state?.currency, this.state?.locale)}</h5>
            }
        </React.Fragment>)
    }

    getCategoryList(catList) {
        // const catList = this.state?.baseCategoryItem?.length > 0 ? this.state.baseCategoryItem?.[0]?.subServiceList : this.props?.diagnostic?.walkinCats?.[0]?.subServiceList.slice();
        let listItems = [];
        catList?.map((catItem) => {
            listItems.push(
                <Link key={`catItem-link-${catItem.id}`} href={`/diagnostic/${CommonUtil.convertToSlug(catItem.header)}`}>
                    <a>
                        <div key={`catItem+${catItem.id}`}
                            className={`pointer-cursor category-item ${catItem.isSelected ? "active" : ""}`}
                            onClick={() => {
                                catItem.isSelected = !catItem.isSelected;
                                console.log("Item: ", catItem);
                                // Auth.navigateTo(`/diagnostic/${CommonUtil.convertToSlug(catItem.header)}/${catItem.id}`);
                                this.setState({ ...this.state },
                                    () => {
                                        // this.getCategoryFilteredTestList(false, false);
                                    })
                            }}>
                            {catItem?.imageURL && catItem?.imageURL != "" &&
                                <img loading="lazy" src={catItem?.imageURL?.split(".png")[0] + "hdpi.png"} alt="category-img" width="100%" className="w-100" />
                            }
                            <p className='text-center mt-2'>{catItem.header}</p>
                        </div>
                    </a>
                </Link>
            );
        }
        );
        return listItems;
    }

    getCategoryListUI(catList) {
        let list = this.getCategoryList(catList);
        let categoryList = [];
        categoryList = list?.length > 10 ? this.createGroups(list, 2) : list;
        let categoryListUi = [];
        categoryList.forEach(card => {
            categoryListUi.push(<div key={Math.random()} >
                {card}
            </div>);
        });

        // this.setState({ isLoading: false })
        return (categoryListUi.length > 0 && categoryListUi);
    }

    createGroups(arr, chunkCount) {
        const chunks = [];
        let init = 0;
        if (arr.length > chunkCount) {
            while (arr.length >= init) {
                let slicedArray = null;
                if (arr.length > init + chunkCount) {
                    slicedArray = arr.slice(init, init + chunkCount);
                } else {
                    slicedArray = arr.slice(init, arr.length);
                }
                chunks.push(slicedArray);
                init += chunkCount;
            }
        } else {
            chunks.push(arr);
        }
        // alert(JSON.stringify(arr));
        return chunks;
    }

    componentWillUnmount(): void {
        $('#feedBackModal').modal('hide');
    }

    getServicesInfoText = (item) => {
        let concatText = '';
        item?.displayDescriptionList?.map((desc, index) => {
            desc?.content?.map((text, index) =>
                concatText = concatText + text
            )
        });
        return concatText;
    }

    getActionTime() {
        const date = new Date(); // creates a new date object with the current date and time

        const hours = date.getHours(); // gets the hours (0-23)
        const minutes = date.getMinutes(); // gets the minutes (0-59)
        const ampm = hours >= 12 ? 'PM' : 'AM'; // sets the AM or PM suffix based on the hours
        const hours12 = hours % 12 || 12; // converts the hours to 12-hour format

        const day = date.getDate(); // gets the day of the month (1-31)
        const month = date.toLocaleString('default', { month: 'short' }); // gets the short month name (e.g. "Apr")
        const year = date.getFullYear(); // gets the year (e.g. 2023)

        const formattedDate = `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}, ${day} ${month} ${year}`; // creates the formatted date string
        return formattedDate;
    }

    render() {
        let { t } = this.props;
        let location = LocationDropdwnService.getSerLocation();
        let data = this.props?.dashboard?.dashboardData;

        let mostbookedList = [];
        console.log("mostbooked==>" + JSON.stringify(mostbookedList))
        if (data?.mostBookedDiagnosticList && data?.mostBookedDiagnosticList?.length > 0) {
            mostbookedList = data?.mostBookedDiagnosticList?.length > 3 ? data?.mostBookedDiagnosticList?.slice(0, 3) : data?.mostBookedDiagnosticList;
        }

        let partsList = data?.ailmentList;
        let bannerFilteredList = data?.bannerList;
        let baseCategoryItem = this.state?.baseCategoryItem ? this.state?.baseCategoryItem : this.props?.diagnostic?.healthCat;
        let catList = this.state.catList ? this.state.catList : this.props?.categories;
        // let catList = this.state?.catList;
        let selectedServiceListMap = this.props?.diagnostic?.selectedServiceList || new Map<number, any>();
        let selMBList = [];
        if (selectedServiceListMap.size > 0 && mostbookedList?.length > 0) {
            selMBList = Array.from(selectedServiceListMap.values());
            mostbookedList?.forEach(packItem => {
                selMBList.forEach(selItem => {
                    if (packItem.serviceId === selItem.serviceId)
                        packItem.isSelected = true;
                });
            });
        }

        let packageList;
        if (this.props?.isSearchEnable && this.props?.diagnostic?.healthPackages)
            packageList = this.props?.diagnostic?.healthPackages;

        else if (this.props?.diagnostic?.healthPackages) {
            if (this.props?.diagnostic?.healthPackages.length >= 4) {
                packageList = this.props?.diagnostic?.healthPackages.slice(0, 4);
            } else {
                packageList = this.props?.diagnostic?.healthPackages;
            }
        } else {
            packageList = [];
        }

        // Category list
        let selectedCategoryList = this.props?.diagnostic?.healthSelCat?.[0]?.subServiceList.length > 0 ? this.props?.diagnostic?.healthSelCat?.[0]?.subServiceList : [];

        // package list
        let selPackList = [];
        if (selectedServiceListMap.size > 0 && packageList.length > 0) {
            selPackList = Array.from(selectedServiceListMap.values());
            packageList.forEach(packItem => {
                selPackList.forEach(selItem => {
                    if (packItem.serviceId === selItem?.serviceId)
                        packItem.isSelected = true;
                });
            });
        }

        let serviceList;
        let fileterdServices = this.state?.fileterdServices.length > 0 ? this.state?.fileterdServices : this.props?.diagnostic?.diagServiceData?.fileterdServices
        if (this.state.searchText == "") {
            if (!this.props?.isSearchEnable && fileterdServices?.length > 4) {
                serviceList = fileterdServices?.slice(0, 4)
            } else if (fileterdServices && fileterdServices?.length > 0) {
                serviceList = fileterdServices
            } else {
                serviceList = [];
            }
        } else {
            serviceList = this.props?.diagnostic?.serviceTests;
        }

        let webpSupport = FileUtil.checkWebPSupport();

        if (!bannerFilteredList || bannerFilteredList.length == 0) {
            bannerFilteredList = [];
        }

        return (
            <React.Fragment>
                <section className='home'>
                    {bannerFilteredList && bannerFilteredList?.length > 0 &&
                        <div className='banner'>
                            <div className='bannerslider-sec'>
                                <div className='banner-sliderwidget banner-slider'>
                                    <Carousel
                                        responsive={bannerres}
                                        keyBoardControl={true}
                                        showDots={true}
                                        arrows={false}
                                        autoPlay={true}
                                        infinite={true}
                                        containerClass="carousel-container"
                                        itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                        {
                                            bannerFilteredList?.map((bannerItem) =>
                                                <div key={`bannerItem+${bannerItem.id}`}
                                                    className="banner-topsliderinfo"
                                                    onClick={() => { this.manageDeepLinkOnClick(bannerItem) }}>
                                                     {bannerItem?.id == '2011' ? <img src={bannerItem?.imageURL} alt="banner-img" width="100%" className="w-100" /> :
                                                 <img src={bannerItem?.imageURL?.split(".png")[0] + (webpSupport && bannerItem?.webImages ? (this.state.isMobileView ? "mbl.webp" : "dkp.webp") : (this.state.isMobileView ? "hdpi.png" : "xxhdpi.png"))} alt="banner-img" width="100%" className="w-100" />}   
                                                </div>
                                            )
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='mobilemenu-info d-block d-sm-none'>
                        <div className='row'>
                            <div className='col-3 pointer-cursor'>
                                <Link href="/test">
                                    <div className='bg-white text-center'>
                                        <img className='mb-2 booktest-icon' src={Config.portal.iconspath + "images/ic-booktest.svg"} alt="booktest" />
                                        <p><b>Book Your <br />Test</b></p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-3 pointer-cursor'>
                                <Link href="/health-checkup">
                                    <div className='bg-white text-center'>
                                        <img className='mb-2' src={Config.portal.iconspath + "images/ic-healthpackage.svg"} alt="healthcheckups" />
                                        <p><b>Health <br />Checkups</b></p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-3 pointer-cursor'>
                                <Link href="/mydashboard?type=2">
                                    <div className='bg-white text-center'>
                                        <img className='mb-2' src={Config.portal.iconspath + "images/ic-downloadreports.svg"} alt="downloadreports" />
                                        <p><b>Download <br />Reports</b></p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-3 pointer-cursor'>
                                <Link href="/diagnostic/home-sample-collection">
                                    <div className='bg-white text-center'>
                                        <img className='mb-2' src={Config.portal.iconspath + "images/ic-homecollection.svg"} alt="homecollection" />
                                        <p><b>Home <br />Collection</b></p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='popular-tests py-4'>
                        <div className='container'>
                            <h4 className='d-inline-block'>Popular Tests</h4>
                            <Link href={`test/`}>
                                <a className='float-right view-morebtn bg-transparent d-block d-xl-none'>VIEW MORE</a>
                            </Link>
                            <div className='row'>
                                <div className='col-xl-10'>
                                    <div className='test-slider'>
                                        <Carousel partialVisible
                                            responsive={responsive}
                                            keyBoardControl={true}
                                            arrows={false}
                                            containerClass="carousel-container"
                                            itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                            {mostbookedList?.map(testItem =>

                                                <div className='slider-info' key={`mostbooked_home_${testItem?.serviceId}`}>
                                                    <div className={`testitem-cardinfo ${testItem?.isSelected ? " added-tocart" : ""}`}>
                                                        <Link href={this.goToDetail(testItem)}>
                                                            <a className='primary-btn'><h6 className='pointer-cursor'>{testItem?.serviceName}</h6>
                                                            </a>
                                                        </Link>

                                                        <div className='cost-info mt-2'>
                                                            {/* {(testItem.grossPrice > testItem.netPrice) &&
                                                                <React.Fragment>
                                                                    <p className='txt-danger'>{Format.currencyFormat(testItem.grossPrice, this.state.currency, this.state.locale)}</p>
                                                                    <h5 className="d-inline-block txt-primary">{Format.currencyFormat(testItem.netPrice, this.state.currency, this.state.locale)}</h5>
                                                                </React.Fragment>
                                                            }
                                                            {(testItem.grossPrice === testItem.netPrice) &&
                                                                <h5 className="d-inline-block txt-primary">{Format.currencyFormat(testItem.netPrice, this.state.currency, this.state.locale)}</h5>
                                                            } */}
                                                            {this.setPriceDetails(testItem)}
                                                            <button className='float-right bg-white' onClick={() => {
                                                                testItem.isSelected = !testItem?.isSelected
                                                                this.addToCart(testItem);
                                                                if (testItem.isSelected) {
                                                                    CleverTapUtil.customEventTrigger({
                                                                        [AnalyticsConstant.LABEL_NAME]: testItem.serviceName,
                                                                        [AnalyticsConstant.LABEL_ID]: testItem.serviceId,
                                                                        [AnalyticsConstant.PRICE]: testItem.netPrice,
                                                                        [AnalyticsConstant.ACTION_TIME]: this.getActionTime(),
                                                                    },
                                                                        AnalyticsConstant.DIAGNOSTIC_ADD_CART);
                                                                    ToastComponent.success(this.props.t('ADDED_TO_CART'));
                                                                }
                                                                //added just to call render
                                                                this.setState({ ...this.state })
                                                            }}><b>{testItem.isSelected ? t("common:REMOVE_BUTTON") : t("common:BOOK")}<i className="fa fa-shopping-cart ml-2" /></b></button>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                                <div className='col-xl-2 d-none d-xl-block text-center'>
                                    <div className='parent w-100'>
                                        <div className='child'>
                                            <Link href={`/test/`}>
                                                <a className='view-morebtn bg-transparent'><img className='mx-auto' src={Config.portal.iconspath + "images/view_more1.svg"} alt='viewmore-icon' />View More</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Upload File */}
                    {/* <div className='upload-fileinfo'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <img src={Config.portal.iconspath + "images/upload-prescription.png"} alt="upload-pres" className='w-100 h-100' />
                                </div>
                                <UploadFileComponent />
                            </div>
                        </div>
                    </div> */}

                    {/* our health checkups */}
                    <div className='mt-4'>
                        <div className='health-checkupcard category-filtercard'>
                            <div className='category-filter'>
                                <div className='container'>
                                    <h4 className='d-inline-block'>Our Health Checkups</h4>
                                    {!this.props?.isSearchEnable &&
                                        <Link href={`/health-checkup`}>
                                            <a className='float-right view-morebtn bg-transparent d-block d-xl-none'>VIEW MORE</a>
                                        </Link>}
                                    <CategoryComponent isSearchEnable={this.props?.isSearchEnable} parentProps={this.props} selCatList={selectedCategoryList} baseCatItem={baseCategoryItem}></CategoryComponent>
                                </div>
                            </div>
                            {/* packageList */}
                            {packageList?.length > 0 ?
                                <div className='categorycard-widget'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-xl-10'>
                                                <div className='healthcheckup-slider'>
                                                    <Carousel partialVisible
                                                        responsive={healthcheckupservices}
                                                        keyBoardControl={true}
                                                        arrows={false}
                                                        containerClass="carousel-container"
                                                        itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">

                                                        {packageList?.map(packItem => {

                                                            let paramCount = 0;
                                                            let descList = [];
                                                            if (packItem?.displayDescriptionList) {
                                                                packItem?.displayDescriptionList.forEach(item => {
                                                                    if (item?.title == 'Parameter Count') {
                                                                        if (item?.descDetails
                                                                            && item?.descDetails[0]?.content && item?.descDetails[0]?.content[0]) {
                                                                            paramCount = Math.floor(+item?.descDetails[0]?.content[0]);
                                                                        }
                                                                    } else if (item?.title == 'Tests/Parameters') {
                                                                        if (item?.descDetails && item?.descDetails?.length > 0) {
                                                                            item.descDetails.forEach(desc => {
                                                                                descList.push(<li key={'desc-' + desc?.title}><small>{desc?.title} &gt;</small></li>);
                                                                            })
                                                                        }
                                                                    }
                                                                }
                                                                );
                                                            }
                                                            return (
                                                                <div className='slider-info'>
                                                                    <div className={`categorycard-info${packItem?.isSelected ? " added-tocart" : ""}`}>
                                                                        <Link href={this.goToDetail(packItem, true)}><a className='txt-black'><h6 className='text-uppercase mb-0 pointer-cursor'>{packItem?.serviceName}</h6></a></Link>
                                                                        {paramCount > 0 && <small className='txt-bold txt-primary'>Parameters Covered {paramCount}</small>}
                                                                        <div className='test-info mt-3'>
                                                                            <Link href={this.goToDetail(packItem, true)}><a className='txt-black'><p className='text-uppercase pointer-cursor txt-bold'><u>Tests/Parameters</u></p></a></Link>
                                                                            <ul>
                                                                                {descList}
                                                                            </ul>
                                                                        </div>
                                                                        <div className='cost-info mt-3'>
                                                                            {/* {(packItem.grossPrice > packItem.netPrice) &&
                                                                            <React.Fragment>
                                                                                <p className='txt-danger'>{Format.currencyFormat(packItem.grossPrice, this.state.currency, this.state.locale)}</p>
                                                                                <h5 className="d-inline-block txt-primary">{Format.currencyFormat(packItem.netPrice, this.state.currency, this.state.locale)}</h5>
                                                                            </React.Fragment>
                                                                        }
                                                                        {(packItem.grossPrice === packItem.netPrice) &&
                                                                            <h5 className="d-inline-block txt-primary">{Format.currencyFormat(packItem.netPrice, this.state.currency, this.state.locale)}</h5>
                                                                        } */}
                                                                            {this.setPriceDetails(packItem)}
                                                                            <img src={packItem.thumbnailImageUrl && packItem.thumbnailImageUrl.length > 0 ? packItem.thumbnailImageUrl : (Config.portal.iconspath + "images/ic-healthcheckup.svg")} alt='healthcheckup-icon' />
                                                                        </div>
                                                                        <div className='centrecart_btn my-2'>
                                                                            {/* <button className='centre-btn mr-2'>Prefered Centre</button> */}
                                                                            <button className='cart-btn bg-white txt-bold' onClick={() => {
                                                                                packItem.isSelected = !packItem?.isSelected
                                                                                this.addToCart(packItem);
                                                                                if (packItem.isSelected) {
                                                                                    CleverTapUtil.customEventTrigger({
                                                                                        [AnalyticsConstant.LABEL_NAME]: packItem.serviceName,
                                                                                        [AnalyticsConstant.LABEL_ID]: packItem.serviceId,
                                                                                        [AnalyticsConstant.PRICE]: packItem.netPrice,
                                                                                        [AnalyticsConstant.ACTION_TIME]: this.getActionTime(),
                                                                                    },
                                                                                        AnalyticsConstant.DIAGNOSTIC_ADD_CART);
                                                                                    ToastComponent.success(this.props.t('ADDED_TO_CART'));
                                                                                }
                                                                                //added just to call render
                                                                                this.setState({ ...this.state })
                                                                            }}>{packItem?.isSelected ? t("common:REMOVE_BUTTON") : t("common:BOOK")}<i className="fa fa-shopping-cart ml-2" /></button>
                                                                        </div>
                                                                    </div>
                                                                </div>);
                                                        }
                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>
                                            <div className='col-xl-2 d-none d-xl-block text-center'>
                                                <div className='parent w-100'>
                                                    <div className='child'>
                                                        <Link href={`/health-checkup`}>
                                                            <a className='view-morebtn bg-transparent'><img className='mx-auto' src={Config.portal.iconspath + "images/view_more.svg"} alt='viewmore-icon' />View More &gt;</a>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='container'>
                                    <p className='p-2 widget-shadow bg-white'><b>{t('common:NO_DATA_FOUND')}</b></p>
                                </div>
                            }
                        </div>
                    </div>

                    {/* parts List */}
                    <div className='search-slidersec mt-4'>
                        <div className='container'>
                            <h4 className='txt-primary'>Search By Relevance</h4>
                            {partsList && partsList?.length > 0 &&
                                <div className="category-slider mt-4">
                                    <Carousel
                                        responsive={categoryres}
                                        key={`${Math.random()}catslider`}
                                        keyBoardControl={true}
                                        containerClass="carousel-container"
                                        itemClass="carousel-item-padding-40-px">
                                        {this.getCategoryListUI(partsList)}
                                    </Carousel>
                                </div>
                            }
                        </div>
                    </div>

                    {/* Our Services */}
                    <div className='mt-4'>
                        <div className='our-servicescard category-filtercard'>
                            <div className='category-filter'>
                                <div className='container'>
                                    <h4 className='d-inline-block'>Our Services</h4>
                                    {!this.props?.isSearchEnable &&
                                        <Link href={`/services`}>
                                            <a className='float-right view-morebtn bg-transparent d-block d-xl-none'>VIEW MORE</a>
                                        </Link>}
                                    {/* Category List */}
                                    {this.state?.searchText == "" &&
                                        <div className="filtercontent mt-3">
                                            {catList?.length > 0 &&
                                                <div className="filtercontent mt-3">
                                                    {
                                                        catList?.map(catItem =>
                                                            <span key={`catItem+${catItem?.categoryId}`}
                                                                className={`pointer-cursor category-item ${catItem?.isSelected ? "active" : ""}`}
                                                                onClick={() => {
                                                                    catItem.isSelected = !catItem?.isSelected;
                                                                    this.setState({ catList: catList },
                                                                        () => {
                                                                            this.getFilteredSubServices();
                                                                        })
                                                                }}>{catItem?.categoryName}</span>
                                                        )}
                                                </div>
                                            }
                                        </div>}
                                </div>
                            </div>
                            {this.state?.searchText == "" && serviceList && serviceList?.length > 0 &&
                                <div className='categorycard-widget'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-xl-10'>
                                                <div className='services-slider'>
                                                    <Carousel partialVisible
                                                        responsive={healthcheckupservices}
                                                        keyBoardControl={true}
                                                        arrows={false}
                                                        containerClass="carousel-container"
                                                        itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                                        {serviceList?.map(item =>
                                                            <div className='slider-info' key={`catItem+${item?.categoryId}`}>
                                                                <div className='widget-shadow bg-white'>
                                                                    <Link href={`/services/${item?.slugName}/`}>
                                                                        <a>
                                                                            <h6 className='txt-primary'>{item?.categoryName}</h6>
                                                                        </a>
                                                                    </Link>

                                                                    {/* <ShowMoreText
                                                                        lines={5}
                                                                        more=""
                                                                        less=""
                                                                        className="content-css"
                                                                        anchorClass="my-anchor-css-class"
                                                                        expanded={false}
                                                                        truncatedEndingComponent={"... "}
                                                                    >
                                                                        <div>
                                                                            {item?.displayDescriptionList?.map((desc, index) =>
                                                                                <React.Fragment key={`disItem+${index}`}>
                                                                                    {desc?.content?.map((text, index) =>
                                                                                        <p key={`contentItem+${index}`} className='mb-2'>{text}</p>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    </ShowMoreText> */}

                                                                    <p className='mb-2 service_txt'>
                                                                        <TextTruncate
                                                                            line={3}
                                                                            element="span"
                                                                            truncateText="…"
                                                                            text={this.getServicesInfoText(item)}
                                                                        />
                                                                    </p>
                                                                    <div className='knowmore_margin'>
                                                                        <Link href={`/services/${item?.slugName}/`}>
                                                                            <a className='bg-white mt-2 knowmore-btn'>Know more &gt;</a>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>
                                            {!this.props?.isSearchEnable &&
                                                <div className='col-xl-2 d-none d-xl-block text-center'>
                                                    <div className='parent w-100'>
                                                        <div className='child'>
                                                            <Link href={`/services`}>
                                                                <a className='view-morebtn bg-transparent'><img className='mx-auto' src={Config?.portal?.iconspath + "images/view_more.svg"} alt='viewmore-icon' />View More &gt;</a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>}
                            {(!serviceList || (serviceList && serviceList?.length === 0)) &&
                                <div className='container'>
                                    <p className='p-2 widget-shadow bg-white'><b>{t('common:NO_DATA_FOUND')}</b></p>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='mt-4'><HappyCustomerList disableHappyCustomer={true}></HappyCustomerList></div>
                    <div className='container mt-3'><hr className='m-0' /></div>
                    <div className='container'>
                        <h1 className="center mt-4 mb-4 newd-border text-center"><span>Why Choose Vijaya Diagnostic Centre</span></h1>
                        <p className='about_vdc_content'>Vijaya diagnostic centre is one of the leading diagnostic centre chains in India. We have been offering
                            our ever-expanding client base that consists of patients and corporate clients quality assured diagnostic
                            services for more than 40 years now.</p>
                        <p className='about_vdc_content'>We have put together all the resources on our official website that allows you to  <a className='text-dark font-weight-bold' href='https://www.vijayadiagnostic.com/test'>book tests</a> at Vijaya
                            Diagnostics centre from the comfort of your home with just a swipe, scroll and click.</p>
                        <p className='about_vdc_content'>The Vijaya diagnostics centre <a className='text-dark font-weight-bold' href='https://www.vijayadiagnostic.com/diagnostic/home-sample-collection'>home sample collection</a> team consists of several certified and well-trained personnel that carefully collect samples
                            from patients who are unable to visit our <a className='text-dark font-weight-bold' href='https://www.vijayadiagnostic.com/find-a-centre'>nearest diagnostic centre</a> to submit their samples.</p>
                        <p className='about_vdc_content'>In terms of costs, Vijaya diagnostics' centre <a className='text-dark font-weight-bold' href='https://www.vijayadiagnostic.com/health-checkup'>health packages</a>  all entail reasonable tariffs. Furthermore,  Vijaya diagnostic centre test packages
                            are  carefully designed to suit the needs of patients and their prescriptions.
                            Furthermore, visit any of our 100+ diagnostic centres located in more than 17 cities of India, and you would find that the diagnostic centre is staffed with
                            200+ professionals.</p>
                        <p className='about_vdc_content'>Our diagnostic services are trusted by every age group which is why; we have been successful in serving 50+ million patients and 250+ corporate clients to date! </p>
                        <p className='about_vdc_content'>Please visit the nearest Vijaya diagnostic centre or log on to the official website of our diagnostic centers to find a pathology lab near you today.
                        </p>
                    </div>
                    <div className='platform-info'><HealthcarePlatform /></div>
                    {/* FAQ's Start */}
                    <div className='container'>
                        <div>
                            <Faqs />
                        </div>

                    </div>
                    {/* FAQ's End */}
                    {/* Rating popup */}
                    {(Config.portal?.dashboardConfig?.showFeeback && this.props?.dashboard?.ratingDetail && this.props?.dashboard?.ratingDetail?.userRating === UserRating.INITIAL &&
                        this.props?.dashboard?.ratingDetail?.orderId != null && this.props?.dashboard?.ratingDetail?.orderId != "") &&
                        <React.Fragment>
                            {(Config?.portal?.featureConfig?.popUpMode) ?
                                <React.Fragment>
                                    <div className="modal fade" id="feedBackModal" role="dialog" data-keyboard="false"
                                        data-backdrop="static">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header border-0">
                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div className="modal-body">
                                                    <FeedbackComponent></FeedbackComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment> : <RatingComponent></RatingComponent>
                            }
                        </React.Fragment>
                    }
                    {/* Followup test popup */}
                    {(this.state?.showFollowupPopup) &&
                        <React.Fragment>
                            <div className="modal fade" id="followupTestModal" role="dialog" data-keyboard="false"
                                data-backdrop="static">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header border-0">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <p>{this.state.followupTestMessage}</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                onClick={() => this.onFollowupBook()}
                                                className="link-danger link-txt text-uppercase mr-2" data-dismiss="modal"> {t("common:BOOK")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>
                    }
                </section>

                {this.state.showMobileDiscountPopup &&
                    <div id="mobileDiscountModal" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            {/* <!-- Modal content--> */}
                            <div className="modal-content popup-banner">
                            <img className="backgr-popup" id="img03" src="https://images.vijayadiagnostic.com/website/banner/App_Launch_Banner.jpg" alt=""/>
                                <div className="modal-header close-btn">
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    {/* <h4 className="modal-title"></h4>
                                <img className="banner" src="" alt="Vijaya Diagnostic"></img> */}
                                </div>

                                {/* <div className="modal-footer">
                                    <ul className="app_info_banner">
                                        <li>
                                            <a href="https://apple.co/3SwQoww" target="_blank" className="" aria-label="appLink" rel="nofollow">
                                                <img src="/common-img/app-store.png" alt="app-storeicon" />

                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://bit.ly/vdcappn" target="_blank" className="" aria-label="appLink" rel="nofollow">
                                                <img src="/common-img/google-play.png" alt="google-playicon" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>

                        </div>
                    </div>}
            </React.Fragment >
        )
    }
}

export default connect(withTranslation(['dashboard', 'common'])(HomeComponent));
