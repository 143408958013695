import { connect } from "react-redux";
import checkoutActions from '../checkout/checkout.actions';
import diagnosticActions from '../diagnostics/diagnostic.actions';
import layoutActions from '../layout/layout.actions';
import dashboardActions from './dashboard.actions';
import dashboardReducer from './dashboard.reducers';

const dashboardConnect = (Component) => {
    return connect(dashboardReducer, {
        ...dashboardActions, ...diagnosticActions,
        ...layoutActions, ...checkoutActions
    })(Component)
};

export default dashboardConnect;