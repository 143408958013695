import React from 'react';
import HSPage from '../auth/hspage';
import HomeComponent from '../components/dashboard/home/home.component';
import Layout from '../components/layout/layout.component';
import Meta from '../components/layout/meta/meta.component';
import hs_location_dropdown from '../components/util-components/hs-location/hs_location_dropdown';
import LocationDropdwnService from '../components/util-components/hs-location/hs-location.service';
import DiagnosticService from '../components/diagnostics/diagnostic.service';
import { FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST } from '../components/diagnostics/diagnostic.types';
import LayoutTypes from '../components/layout/layout.types';
import PopCityConst from '../../public/vdc/popularCitiesConstants';

const schema = {
    "@context": "http://www.schema.org",
    "@type": "DiagnosticLab",
    "name": "Vijaya Diagnostic Centre Limited",
    "alternateName": "Vijaya Diagnostic Centre",
    "url": "https://www.vijayadiagnostic.com/",
    "logo": "https://www.vijayadiagnostic.com/vdc/icons/favicon.png",
    "description": "Vijaya Diagnostic Centre is a leading blood test laboratory in India, offering diagnostic services for 40 years. Book diagnostic tests online & Download Report!",
    "image": "https://www.vijayadiagnostic.com/vdc/icons/favicon.png",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Ground Floor, FPAI Building, Punjagutta Officers Colony, Near Topaz Building",
        "addressLocality": "Hyderabad",
        "addressRegion": "Telangana",
        "postalCode": "500082",
        "addressCountry": "India"
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "040 2100 0000",
        "contactType": "customer service"
    },
    "sameAs": [
        "https://www.facebook.com/vijayadiagnostic",
        "https://twitter.com/vijayadiagnostic",
        "https://www.linkedin.com/company/vijayadiagnostic",
        "https://www.instagram.com/vdcconnect/",
        "https://www.youtube.com/vdcconnect/"
    ]
};

export default class Home extends HSPage {
    props: any

    render() {
         return (
            <Layout>
                <Meta schema={schema} title={'Vijaya Diagnostic Centre- Health Checkup and Pathology Lab Test'}
                    description={'Vijaya Diagnostic Centre is a leading blood test laboratory in India, offering diagnostic services for 40 years. Book diagnostic tests online & Download Report!'}
                    canonical={'https://www.vijayadiagnostic.com'}
                    keywords={'book tests at vijaya diagnostic, vijaya diagnostics home sample collection, vijaya diagnostics online booking, vijaya diagnostics online test booking, vijaya diagnostics health packages, vijaya diagnostic test packages, vijaya diagnostic centres near me, vijaya diagnostic centre, nearest vijaya diagnostic centre, diagnostic centres near me, nearest diagnostic centre, best diagnostic centre, diagnostics near me'}
                ></Meta>

                <HomeComponent pageProps={this.props?.pageProps} categories={this.props?.pageProps?.categories} serviceMap={this.props?.pageProps?.serviceMap} />
            </Layout>
        )
    }
}

Home.getInitialProps = async (ctx) => {

    
    if (HSPage.isProtectedRoute(ctx)) return {};
    await hs_location_dropdown.getInitLocation(ctx);
    let requiredprops = await HomeComponent?.getInitialProps(ctx);
    let location = LocationDropdwnService?.getSerLocation();
    let selectedcity = PopCityConst?.popularCity?.filter(city => city?.slugName == location?.citySlug);
    let popularCities = PopCityConst?.popularCity;
    let requestBody = {
        "state": selectedcity[0]?.stateId,
        "city": selectedcity[0]?.cityId,
        "area": 0,
        "firstChar": '',
        "skip": 0,
        "size": 50
    }

    
    await DiagnosticService.getDiagnosticCentreDetails(requestBody).then((response) => {
        response = response?.data;
        ctx.store.dispatch({ type: FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST, payload: response });
        

    }).catch((err) => {

        ctx.store.dispatch({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: false });
        console.log(err);
    });
    return {
        namespacesRequired: ['login'], categories: requiredprops?.categories, serviceMap: requiredprops?.serviceMap,popularCities
    };
}


