import React from 'react';
import Auth from '../../../auth/auth';
import HSComponent from "../../../auth/hscomponent";
import RouterUtil from '../../../base/utils/routerUtil';
import HsSearch from '../../util-components/hs-Search/hs_search';

class VDCSearchComponent extends HSComponent {
    props: any;
    state: any = {
        query: '',
        searchTermResults: []
    }

    searchOptions = {
        "placeholder": "Search for test and health checkup",
        "inputType": "any",
        "searchType": true,
        "selectedDisplayVar": "testName",
        "searchviewcolumnList": [
            {
                "variable": "serviceName",
                "filters": "text"
            }]
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    handleInputChange = (eventRTerm) => {
        const value = eventRTerm.target.value;
        console.log("handleInputChange: ", eventRTerm.keyCode);
        if (eventRTerm.keyCode == 13 && value && value.length >= 3) {
            this.props?.onHandleSearchRes(value);
        } else if (!value || value == "")
            this.props?.onHandleSearchRes();
        this.setState({ query: value });
    }

    async onSelect(e) {
        Auth.navigateTo(`/diagnostic/walkin/listview?searchTerm=${e.serviceName}`);
    }

    searchWithTerm = (term, isEnter = false) => {
        if (isEnter && !this.props.isSearchPage) {
            // this.props?.onHandleSearchRes(term);
            Auth.navigateTo(`/diagnostic/walkin/listview?searchTerm=${term}`);
        } else if (isEnter && this.props.isSearchPage) {
            this.props?.onHandleSearchRes();
            Auth.navigateTo(`/diagnostic/walkin/listview?searchTerm=${term}`);
        } else if (term && term.length >= 3) {
            this.props?.onHandleSearchRes(term);
        } else {
            this.props?.onHandleSearchRes();
        }
        this.setState({ query: term });
    }

    render() {
        console.log("VDCSearchComponent: ", this.state.query);
        return (
            <React.Fragment>
                {/* <input type="text" className="w-auto search_input" value={this.state.query}
                    onChange={(event) => this.handleInputChange(event)}
                    onKeyUp={(event) => this.handleInputChange(event)}
                    placeholder="Search for test and health checkup" /> */}
                <div className='d-inline-block'>
                    <HsSearch className='w-auto search_input' options={this.searchOptions} selectedFile={this.state?.selectedResult}
                        searchResult={this.props?.results} searchTrigger={this.searchWithTerm} selectTrigger={this.onSelect} />
                </div>
            </React.Fragment>
        )
    }
}

export default VDCSearchComponent;