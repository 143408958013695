import Item from "antd/lib/list/Item";
import Auth from "../../../auth/auth";
import HSComponent from "../../../auth/hscomponent";
import { Config } from "../../../base/portal_env_config/config";
import CommonUtil from "../../../base/utils/commonUtil";
import { HSServicesRequest } from "../../../model/hsservicerequest";
import ErrorBoundary from "../../layout/widgets/errorboundary/errorboundary.component";
import LocationDropdwnService from "../../util-components/hs-location/hs-location.service";
import DiagnosticService from "../diagnostic.service";
import VDCSearchComponent from "./vdcsearch.component";

class CategoryComponent extends HSComponent {
    state: any = {
        searchTermResults: []
    };
    props: any;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    async getCategoryFilteredTestList(isSearch: boolean = false, searchText: any = "") {
        console.log("getCategoryFilteredTestList: ", this.props.baseCatItem);
        if (isSearch && searchText == "") {
            this.setState({ ...this.state, searchTermResults: [] })
            return;
        }
        // this.props.parentProps.setLayoutLoading(true)
        let hsServiceRequest: HSServicesRequest = new HSServicesRequest();
        let isItemSeleceted = false;
        let departmentId = +this.props.baseCatItem?.[0]?.categoryId;
        if (!isSearch)
            this.props?.parentProps.setHealthSelCatList([]);
        console.log("DepartId: " + departmentId);

        let selectedCategories = [];

        this.props.baseCatItem?.[0]?.subServiceList?.forEach(category => {
            if (category.isSelected) {
                isItemSeleceted = true;
                hsServiceRequest.departmentIdList3 = [...(hsServiceRequest?.departmentIdList3 || []), +category.categoryId];
                selectedCategories.push(category);
                console.log("selectedCategory: ", selectedCategories);
            }
        });

        if (!isSearch) {
            this.props.parentProps.setLayoutLoading(true)
            this.props?.parentProps?.setHealthSelCatList(this.props.baseCatItem);
        }

        let pinCode = LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode

        await DiagnosticService.getDiagnosticWalkinTestList({
            searchTerm: searchText,
            pinCode: pinCode,
            departmentIdList2: hsServiceRequest.departmentIdList2 || [],
            from: 0,
            size: 100,
            popularOnly: false,
            brandId: Config?.portal?.appId,
            pocId: 0,
            parentProfileId: this.props?.parentProps.auth?.userDetails?.profileId || 0,
            departmentIdList4: hsServiceRequest.departmentIdList4 || [],
            patientProfileId: this.props?.parentProps.auth?.userDetails?.profileId || 0,
            departmentIdList3: hsServiceRequest.departmentIdList3 || [],
            homeCollections: false,
            categoryId: 4,
            type: 0
        }).then(res => {
            this.props.parentProps.setLayoutLoading(false)
            console.log("Result: ", res.data);
            if (res.data && res.data.length > 0) {
                if (!isSearch)
                    this.props.parentProps.setHealthPackList(res.data);
                else
                    this.setState({ ...this.state, searchTermResults: res?.data })
            }
            else {
                this.setState({ ...this.state, searchTermResults: [] })
            }
        }).catch((err) => {
            console.log(err)
            this.props.parentProps.setLayoutLoading(false)
        })
    }

    onHandleSearch = (searchtext = "") => {
        this.getCategoryFilteredTestList(true, searchtext);
        this.setState({ query: searchtext })
    }

    navToDetail = (slugName) => {
        let address = LocationDropdwnService.getSerLocation();
        let cityName = address?.cityName ? address?.cityName : 'hyderabad';
        Auth.navigateTo(`/health-checkup/${CommonUtil.convertToSlug(cityName)}/${slugName}`);
    }

    render() {
        let catList = this.props.baseCatItem?.[0]?.subServiceList;
        let selCategories = this.props?.selCatList;
        console.log("selectedCtegoryList: ", selCategories);


        if (selCategories?.length > 0 && catList?.length > 0) {
            catList.forEach(dataItem => {
                selCategories.forEach(item => {
                    if (item.isSelected && dataItem.categoryId === item.categoryId)
                        dataItem.isSelected = true;
                });
            });
        }

        return (<ErrorBoundary>
            {this.props.isSearchEnable &&
                <VDCSearchComponent results={this.state.searchTermResults} onHandleSearchRes={this.onHandleSearch} onNavToDetail={this.navToDetail}></VDCSearchComponent>}
            {catList?.length > 0 &&
                <div className="filtercontent mt-3">
                    {
                        catList?.map(catItem =>
                            <span key={`catItem+${catItem.categoryId}`}
                                className={`pointer-cursor category-item ${catItem.isSelected ? "active" : ""}`}
                                onClick={() => {
                                    catList?.forEach(itm => {
                                        if (itm.categoryId != catItem.categoryId) {
                                            itm.isSelected = false;
                                        }
                                    })
                                    catItem.isSelected = !catItem.isSelected;
                                    this.setState({ ...this.state },
                                        () => {

                                            this.getCategoryFilteredTestList();
                                        })
                                }}>{catItem.categoryName}</span>
                        )}
                </div>
            }
        </ErrorBoundary>)
    }

}
export default CategoryComponent;