import AxiosService from '../../base/portal_env_config/axiosService';
import { paths } from '../../base/portal_env_config/urlconstants';
import { BaseResponse } from '../../model/base/baseresponse';

export default class DashboardService {
    public static async getDataWhenLoggedIn(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_DATA_WHEN_LOGGED_IN, requestBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getDataWhenLoggedOut(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_DATA_WHEN_LOGGED_OUT
            , requestBody).then((res) => {
                return Promise.resolve(res);
            }).catch(err => {
                if (err) {
                    console.log(err);
                    return Promise.reject(err);
                }
            });
    }

    public static async getFrequentPurchases(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_FREQUENT_PURCHASES, requestBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getUserMetricsAfterLogin(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_USER_METRICS, requestBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getSessionForPatient(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.CHECK_ACTIVE_PATIENT_SESSION, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async globalSearch(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GLOBAL_SEARCH, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getSpecificPOCData(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_SPECIFIC_POC_DATA, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getRecentPrescriptionsForPatient(parentProfileId): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.RECENT_PRESCRIPTIONS + parentProfileId).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getPharmacySellingPrice(params): Promise<any> {
        return AxiosService.httpPost(paths.SELLING_PHARMACY_PRICE, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getUpcomingConsultations(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_UPCOMING_CONSULTATION, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async updateUserRating(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.SET_USER_RATING, requestBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getRatingDetails(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_RATING_DETAILS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getHealthtipDetails(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_HEALTH_TIP_DETAILS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getPhleboOderInProgress(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_PHLEBO_ORDER_IN_PROGRESS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async updatePrivilegeCardMems(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.UPDATE_PRIVILEGE_CARD_MEM, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getProfilesWithParentId(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_PROFILES_WITH_PARENT_PROFI_ID, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getPrivilegeCardBenefits(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_PRIVILEGE_CARD_BENEFITS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
}