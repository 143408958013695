import { Question } from "../phr/question";

export class UserRating {

    public static INITIAL: number = 0;
    public static NOT_INTERESTED: number = UserRating.INITIAL + 1;
    public static DO_IT_LATER: number = UserRating.NOT_INTERESTED + 1;
    public static COMPLETED: number = UserRating.DO_IT_LATER + 1;

    orderId: string;
    profileId: number;
    userRating: number;  // rating given by user
    status: number;
    brandId: string;
    ratingScale: number; // like rating scale is 1-5 or 1-10
    bookingType: number;
    cartItemType: number;
    bookingSubType: number;
    question: [Question];
    popupDesc: string;//popup desc
    popupTitle: string;//popup title
    ratingPageDesc: string;//html content
    ratingQuestion: string;
    ratingPageTitle: string;
}